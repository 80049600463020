import { Link, navigate } from 'gatsby';
import { find, first } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';
import { createSelector } from 'reselect';

import { projectMap } from '../../resources/cert-and-project-map';
import { FlashMessages } from '../Flash/redux/flash-messages';
import CustomButton from '../ui/Button';
import { ButtonTypes } from '../ui/Button/button-types';
import Visibility from '../../assets/icons/visibility';
import ArrowCircleRight from '../../assets/icons/arrow-circle-right';

import './certification.css';

configureAnchors({ offset: -40, scrollDuration: 0 });

const propTypes = {
  completedChallenges: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      solution: PropTypes.string,
      githubLink: PropTypes.string,
      challengeType: PropTypes.number,
      completedDate: PropTypes.number,
      challengeFiles: PropTypes.array
    })
  ),
  createFlashMessage: PropTypes.func.isRequired,
  isHonest: PropTypes.bool,
  t: PropTypes.func.isRequired,
  username: PropTypes.string,
  verifyCert: PropTypes.func.isRequired
};

const mapDispatchToProps = {};

const certifications = Object.keys(projectMap);
const isCertSelector = ({
  isFundamentosDaProgramacaoFrontEndCert,
  isLogicaDeProgramacaoCert,
  isHtmlCssJavascriptCert,
  isReactCert
}) => ({
  isFundamentosDaProgramacaoFrontEndCert,
  isLogicaDeProgramacaoCert,
  isHtmlCssJavascriptCert,
  isReactCert
});

const isCertMapSelector = createSelector(
  isCertSelector,
  ({
    isFundamentosDaProgramacaoFrontEndCert,
    isLogicaDeProgramacaoCert,
    isHtmlCssJavascriptCert,
    isReactCert
  }) => ({
    'Fundamentos da Programação Front-End':
      isFundamentosDaProgramacaoFrontEndCert,
    'Lógica de Programação': isLogicaDeProgramacaoCert,
    'HTML, CSS e Javascript': isHtmlCssJavascriptCert,
    React: isReactCert
  })
);

const honestyInfoMessage = {
  type: 'info',
  message: FlashMessages.HonestFirst,
  variables: {
    dismissible: true
  }
};

const initialState = {
  solutionViewer: {
    projectTitle: '',
    challengeFiles: null,
    solution: null,
    isOpen: false
  }
};

export class CertificationSettings extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };
  }

  getUserIsCertMap = () => isCertMapSelector(this.props);

  isSolved = projectId => {
    const { completedChallenges } = this.props;
    const completedProject = find(
      completedChallenges,
      ({ id }) => projectId === id
    );
    return !!completedProject;
  };

  getProjectSolution = (projectId, link) => {
    const { completedChallenges } = this.props;
    const completedProject = find(
      completedChallenges,
      ({ id }) => projectId === id
    );

    if (!completedProject) {
      return (
        <Link
          id={`btn-for-${link}`}
          rel='noopener noreferrer'
          target='_self'
          href={link}
          width='100%'
          className='project-preview-icon-play'
        >
          <ArrowCircleRight fill='#484848' />
        </Link>
      );
    }

    const { id, solution } = completedProject;

    return (
      <Link
        id={`btn-for-${id}`}
        rel='noopener noreferrer'
        target='_blank'
        href={solution}
        width='100%'
        className='project-preview-icon-visibility'
      >
        <Visibility style={{ cursor: 'pointer' }} />
      </Link>
    );
  };

  renderProjectsFor = (certName, isCert, projectsMap, certSlugTitle) => {
    const { username, isHonest, createFlashMessage, t, verifyCert } =
      this.props;
    const { certSlug } = first(projectsMap[certName]);
    const certLocation = `/certification/${username}/${certSlug}`;

    const createClickHandler = certSlug => e => {
      e.preventDefault();

      if (!isHonest) {
        createFlashMessage(honestyInfoMessage);
        navigate('#honesty-policy');
        return;
      }

      if (isCert) {
        return navigate(certLocation);
      }

      return verifyCert(certSlug);
    };

    const hasFinishedAllProjects = projectsMap[certName].every(({ id }) => {
      return this.isSolved(id);
    });

    return (
      <>
        <h4 className='section-header-text' id={`cert-${certSlug}`}>
          {certSlugTitle}
        </h4>

        <div className='project-list'>
          {projectsMap[certName].map(({ link, title, id }) => (
            <div className='project-item' key={id}>
              <span className='project-title'>
                {this.isSolved(id) ? (
                  <Link to={link}>
                    {t(`certification.project.title.${title}`, title).replace(
                      '🎓 ',
                      ''
                    )}
                  </Link>
                ) : (
                  <p>{title.replace('🎓 ', '')}</p>
                )}
              </span>

              {this.getProjectSolution(id, link)}
            </div>
          ))}
        </div>

        {hasFinishedAllProjects && (
          <CustomButton
            data-cy={`btn-for-${certSlug}`}
            onClick={createClickHandler(certSlug)}
            buttonType={isCert ? ButtonTypes.Primary : ButtonTypes.Tertiary}
            style={{ padding: '6px 16px', margin: '0 auto' }}
          >
            {isCert ? 'Baixar' : 'Solicitar'}
          </CustomButton>
        )}
      </>
    );
  };

  renderCertifications = (certName, projectsMap) => {
    const { t } = this.props;

    return (
      <div className='certification-container' key={certName}>
        <div className='certification-item'>
          {this.renderProjectsFor(
            certName,
            this.getUserIsCertMap()[certName],
            projectsMap,
            t(`certification.title.${certName}`, certName)
          )}
        </div>
      </div>
    );
  };

  render() {
    return (
      <ScrollableAnchor id='certification-settings'>
        <section className='certification-settings'>
          {certifications.map(certName =>
            this.renderCertifications(certName, projectMap)
          )}
        </section>
      </ScrollableAnchor>
    );
  }
}

CertificationSettings.displayName = 'CertificationSettings';
CertificationSettings.propTypes = propTypes;

export default connect(
  null,
  mapDispatchToProps
)(withTranslation()(CertificationSettings));
